import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import logoish from '../images/mcda_og_blk.svg';
import styled from 'styled-components'
import { Box } from 'theme-ui'


export const SvgWrapper = styled.div`
  display: fixed;
  justify-content: center;
  align-items: center;
  color: inherit;
  background: inherit;
  max-height: 45px;
  max-widthL 150px;
`

// <img className="usa-logo__text" src={logoish}></img>


const Header = ({ children, siteTitle }) => (
<header className="usa-header usa-header--basic usa-header--megamenu"  >
  <div className="usa-nav-container">
    <div className="usa-navbar">


      <div className="usa-logo site-logo;" id="logo">
      <Box   
          sx={{width: ['35%','20%','20%','100%'],
          height:["100%", "100%", "100%"],
      }}
  >

          <Link to="/" title="Home" aria-label="Home">
          <img src={logoish} />

          </Link>

          </Box>
      </div>

      <button className="usa-menu-btn">Menu</button>
    </div>
    {children} </div>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
