import React from 'react';
// import flag from '../../node_modules/uswds/dist/img/us_flag_small.png';
import dotGov from '../../node_modules/uswds/dist/img/icon-dot-gov.svg';
import arrowDown from '../images/download_link.svg';
// import https from '../../node_modules/uswds/dist/img/icon-https.svg';

const Banner = () => (
  <div className="usa-banner">
    <div className="usa-accordion">
      <header className="usa-banner__header">
        <div className="usa-banner__inner">
          <div className="grid-col-auto">
          </div>
          <div className="grid-col-fill tablet:grid-col-auto">
            <p className="usa-banner__header-text">
              We're working to improve and add to this site.
            </p>
            <p className="usa-banner__header-action" aria-hidden="true">
            </p>
          </div>
          <button
            aria-controls="gov-banner"
            aria-expanded={false}
            className="usa-accordion__button usa-banner__button"
          >
            <span className="usa-banner__button-text">Feedback, questions, or request data?</span>
          </button>
        </div>
      </header>
      <div
        id="gov-banner"
        className="usa-accordion__content usa-banner__content"
        hidden
      >
        <div className="grid-row grid-gap-lg">
          <div className="usa-banner__guidance-gov tablet:grid-col-6">
            <img
              className="usa-banner__icon usa-media-block__img"
              src={dotGov}
              alt="Dot gov"
            />
            <div className="usa-media-block__body">
              <p>
                <strong>Let Us Know</strong>
                <br />
                To provide feedback, request additional data or analysis, or ask questions about the site, fill out this <a style={{color:"#07648d"}} href='https://forms.gle/1X7ZjvBRXuRATpr6A'>form.</a>
              </p>
              
            </div>            
          </div>

          <div className="usa-banner__guidance-gov tablet:grid-col-6">
            <img
              className="usa-banner__icon usa-media-block__img"
              src={arrowDown}
              alt="Download icon"
              width="75%"
              height="53%"
            />
            <div className="usa-media-block__body">
              <p>
                <strong>Get Data</strong>
                <br />
                To access the underlying data found on this site, <a style={{color:"#07648d"}} href='https://github.com/milwaukeedao/mke-da-dashboard-data'>click here.</a>
              </p>
              
            </div>            
          </div>
          
        </div>
        
      </div>
      
    
    </div>
  </div>
);

export default Banner;
